import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h3: "h3",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Make sure when you are A/B testing you take into account the size of both features. There are ways to skip loading one feature if it comes with a large overhead that most users wont use."
    }), "\n", _jsxs(_components.p, {
      children: ["There are helpers for A/B testing in ", _jsx(_components.code, {
        children: "use-ab-feature.mts"
      }), ". They use the unique fingerprint of the machine to make sure that the user's view doesn't change even on a refresh."]
    }), "\n", _jsx(_components.h3, {
      id: "init-feature",
      children: _jsx(_components.a, {
        href: "#init-feature",
        children: "Init feature"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Normally this will be used in a ", _jsx(_components.code, {
        children: "mod.mjs"
      }), " file. Initialize the feature with a ", _jsx(_components.code, {
        children: "[namespace, featureName]"
      }), ", enabled percentage (as integer [1,100]), and optionally any options"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import { initABFeature } from \"@/util/use-ab-feature.mjs\";\n\nconst enabled = await initABFeature([\"battles\", \"notificationStyle\"], 50);\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "use-ab-feature",
      children: _jsx(_components.a, {
        href: "#use-ab-feature",
        children: "Use A/B feature"
      })
    }), "\n", _jsx(_components.p, {
      children: "After the feature is initialized you can use it in the mod file or with the hook:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import { useABFeature } from \"@/util/use-ab-feature.mjs\";\n\nconst enabled = useABFeature([\"battles\", \"notificationStyle\"]);\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "options",
      children: _jsx(_components.a, {
        href: "#options",
        children: "Options"
      })
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-ts",
        children: "interface Options {\n  /// Re-roll if feature is enabled with every version\n  includeVersionInSalt?: boolean;\n}\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
